<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 218.454 249" class="icon-dislike">
        <g id="Group_4681" data-name="Group 4681" transform="translate(-15.758 -0.5)">
            <path id="Path_2150" data-name="Path 2150" d="M200.057,77.1c0,16.964-.3,33.956.229,50.919.258,8.159-2.033,12.282-10.693,10.893a23.576,23.576,0,0,0-23.749,10.307c-8.588,10.779-17.979,20.856-27.055,31.206a59.17,59.17,0,0,0-14.658,40.926v3.178c-.487,9.863.372,20.542-11.81,24.607A25.481,25.481,0,0,1,84.65,234.12a66.334,66.334,0,0,1-4.137-51.391,142.6,142.6,0,0,1,6.3-21.286c3.607-8.732,2.948-12.569-8-11.867a90.3,90.3,0,0,1-35.572-4.023,24.579,24.579,0,0,1-18.97-29.127q.216-1.021.518-2.022a11.45,11.45,0,0,0-2.276-10.465,30.275,30.275,0,0,1-1.775-35.687,12.6,12.6,0,0,0,2.09-11.738,17.35,17.35,0,0,1,.443-10.436c3.164-12.225,12.025-21.786,15.389-34.1A15.818,15.818,0,0,1,53.9,1.959c30.605-.415,61.768-5.168,91.457,5.082A108.443,108.443,0,0,0,192.6,15.73c6.542-.7,7.544,2.863,7.487,8.331C199.9,41.728,200.028,59.364,200.057,77.1Z"/>
            <path id="Path_2151" data-name="Path 2151" d="M210.923,76.643c0-17.994.329-36-.229-54-.258-8.045,3.951-8.718,9.819-8.088,5.6.63,13.585-3.106,13.542,7.9-.114,36.36-.143,72.7.186,109.066.086,9.834-6.585,7.272-11.624,7.157s-11.924,2.863-11.752-7.043C211.209,113.36,210.937,94.994,210.923,76.643Z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconDislike',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>
